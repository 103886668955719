exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-laser-treatment-template-js": () => import("./../../../src/templates/laserTreatmentTemplate.js" /* webpackChunkName: "component---src-templates-laser-treatment-template-js" */),
  "component---src-templates-request-appointment-js": () => import("./../../../src/templates/requestAppointment.js" /* webpackChunkName: "component---src-templates-request-appointment-js" */),
  "component---src-templates-reset-archive-js": () => import("./../../../src/templates/resetArchive.js" /* webpackChunkName: "component---src-templates-reset-archive-js" */),
  "component---src-templates-reset-single-js": () => import("./../../../src/templates/resetSingle.js" /* webpackChunkName: "component---src-templates-reset-single-js" */),
  "component---src-templates-single-page-js": () => import("./../../../src/templates/singlePage.js" /* webpackChunkName: "component---src-templates-single-page-js" */)
}

