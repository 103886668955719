import React, { Fragment } from 'react';
import { Script } from 'gatsby';

// See: https://www.paulie.dev/posts/2022/07/how-to-use-gatsbys-script-api-with-google-analytics/
const RootElement = ({ children }) => {
  return (
    <Fragment>
      <Script
        src={`https://www.googletagmanager.com/gtag/js`}
        strategy='off-main-thread'
        forward={[`gtag`]}
        id="gtag-script"
        key="gtag-script"
      />
      <Script
        id='gtag-config'
        key='gtag-config'
        strategy='off-main-thread'
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag(){ window.dataLayer.push(arguments);}
          gtag('js', new Date()); 
          gtag('config', 'G-H6ZHW76GX0', { send_page_view: false })
        //   gtag('config', 'AW-1050338826', { send_page_view: false })
        //   gtag('config', 'AW-1050338826/18wiCICuuMQDEIrM6_QD', {
        //     send_page_view: false,
        //     anonymize_ip: true,
        //     cookie_expires: 0,
        //     phone_conversion_number: "(813)367-1915",
        // })
          `,
        }}
      />
      {children}
    </Fragment>
  );
};

export default RootElement;
